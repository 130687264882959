function splashInAnimationClasses(placement) {
  if (!placement) return [];

  const side = placement.split('-')[0];
  let animation = [];
  let origin = '';

  switch (placement) {
    case 'top-start':
      origin = 'tw-origin-bottom-left';
      break;
    case 'top':
      origin = 'tw-origin-bottom';
      break;
    case 'top-end':
      origin = 'tw-origin-bottom-right';
      break;
    case 'bottom-start':
      origin = 'tw-origin-top-left';
      break;
    case 'bottom':
      origin = 'tw-origin-top';
      break;
    case 'bottom-end':
      origin = 'tw-origin-top-right';
      break;
    case 'left-start':
      origin = 'tw-origin-top-right';
      break;
    case 'left':
      origin = 'tw-origin-right';
      break;
    case 'left-end':
      origin = 'tw-origin-bottom-right';
      break;
    case 'right-start':
      origin = 'tw-origin-top-left';
      break;
    case 'right':
      origin = 'tw-origin-left';
      break;
    case 'right-end':
      origin = 'tw-origin-bottom-left';
      break;
    default:
      break;
  }

  switch (side) {
    case 'top':
      animation = ['[&:not(.hiding)]:tw-animate-splash-in-top', '[&.hiding]:tw-animate-splash-out-top'];
      break;
    case 'bottom':
      animation = ['[&:not(.hiding)]:tw-animate-splash-in-bottom', '[&.hiding]:tw-animate-splash-out-bottom'];
      break;
    case 'left':
      animation = ['[&:not(.hiding)]:tw-animate-splash-in-left', '[&.hiding]:tw-animate-splash-out-left'];
      break;
    case 'right':
      animation = ['[&:not(.hiding)]:tw-animate-splash-in-right', '[&.hiding]:tw-animate-splash-out-right'];
      break;
    default:
      break;
  }

  return [...animation, origin];
}

export default splashInAnimationClasses;
