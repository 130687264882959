function focusFirstFocusableTarget(element, { preventScroll = false } = {}) {
  let focusTarget = element.querySelector('[autofocus]');

  if (!focusTarget) {
    focusTarget = element.querySelector(
      'button:not(:disabled), input:not([type="hidden"]):not(:disabled), textarea:not(:disabled), select:not([hidden]):not(:disabled), a:not(:disabled):not(.disabled), [tabindex]:not([tabindex="-1"]):not([hidden]):not(:disabled)',
    );
  }

  if (focusTarget) {
    focusTarget.focus({ preventScroll });
  }
}

export default focusFirstFocusableTarget;
